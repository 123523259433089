<!-- @format -->

<template>
  <div class="px-3">
    <div v-if="loader && !isEnabled">
      <div role="status" class="animate-pulse bg-white pb-5">
        <div class="flex items-center justify-center mb-4">
          <div class="w-full">
            <div
              class="w-20 h-2.5 mx-4 my-2 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
            ></div>
            <div
              class="w-full h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
            ></div>
          </div>
          <div class="w-full mx-8">
            <div
              class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
            ></div>
            <div
              class="w-full h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
            ></div>
          </div>
        </div>
        <div
          class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
        ></div>
        <div
          class="h-2.5 bg-gray-50 rounded py-4 mx-4 dark:bg-gray-400 max-w-[540px]"
        ></div>
        <div
          class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
        ></div>
        <div
          class="h-2.5 bg-gray-50 rounded py-32 mx-4 dark:bg-gray-400 max-w-[640px] mb-2.5"
        ></div>
        <div
          class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
        ></div>
        <div
          class="h-2.5 bg-gray-50 rounded py-4 mx-4 dark:bg-gray-400 max-w-[540px]"
        ></div>
        <div
          class="flex items-center justify-center my-4"
          v-for="key in 2"
          :key="key"
        >
          <div class="w-full">
            <div
              class="w-20 h-2.5 mx-4 my-2 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
            ></div>
            <div
              class="w-full h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
            ></div>
          </div>
          <div class="w-full mx-8">
            <div
              class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
            ></div>
            <div
              class="w-full h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
            ></div>
          </div>
        </div>
        <div
          class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
        ></div>
        <div
          class="h-2.5 bg-gray-50 rounded py-4 mx-4 dark:bg-gray-400 max-w-[540px]"
        ></div>
        <div
          class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
        ></div>
        <div
          class="h-2.5 bg-gray-50 rounded py-8 mx-4 dark:bg-gray-400 max-w-[540px]"
        ></div>
      </div>
    </div>

    <div v-else class="p-3">
      <div class="flex gap-4">
        <div class="w-1/2 flex flex-col gap-2">
          <label class="text-sm">
            {{ isEnabled === 1 ? "Select Subject" : "Subject" }}
            <span v-if="isEnabled" class="text-red-500">*</span>
          </label>
          <v-select
            v-if="isEnabled"
            v-model="subjectName"
            label="name"
            placeholder="Select Subject"
            @change="errors.selectedSubject = null"
            :options="subjectArea"
            @update:modelValue="choosenSubject"
          ></v-select>
          <div v-else class="border border-gray-200 py-2 px-2 rounded isEdit">
            {{ subjectName }}
          </div>
          <span v-if="errors.selectedSubject" class="text-red-500 text-xs">{{
            errors.selectedSubject
          }}</span>
        </div>
        <div class="w-1/2 flex flex-col gap-2">
          <label class="text-sm">
            {{ isEnabled === 1 ? "Select Staff Group" : " Staff Group" }}
            <span v-if="isEnabled" class="text-red-500">*</span>
          </label>

          <v-select
            v-if="isEnabled"
            v-model="staffGroupName"
            label="name"
            placeholder="Select Staff Group"
            :options="staffGroup"
            @update:modelValue="choosenStaffGroup"
          ></v-select>
          <div v-else class="border border-gray-200 py-2 px-2 rounded isEdit">
            {{ staffGroupName }}
          </div>
          <span v-if="errors.selectedStaffGroup" class="text-red-500 text-xs">{{
            errors.selectedStaffGroup
          }}</span>
        </div>
      </div>
      <div class="w-full flex flex-col gap-2 mt-5" v-if="!isManager">
        <label class="text-sm">
          {{ isEnabled === 1 ? "Select Practice" : " Practice" }}
          <span v-if="isEnabled" class="text-red-500">*</span>
        </label>
        <v-select
          v-if="isEnabled"
          v-model="practiceName"
          label="practice_name"
          placeholder="Select Practice"
          :options="practices"
          @update:modelValue="choosenPractice"
        ></v-select>
        <div v-else class="border border-gray-200 py-2 px-2 rounded isEdit">
          {{ practiceName }}
        </div>
        <span v-if="errors.selectedPracticeId" class="text-red-500 text-xs">{{
          errors.selectedPracticeId
        }}</span>
      </div>
      <div class="flex flex-col mt-5 gap-2">
        <label class="text-sm">
          Complaint Details
          <span v-if="isEnabled" class="text-red-500">*</span>
        </label>
        <textarea
          v-model="complaintDetails"
          @change="errors.complaintDetails = null"
          type="text"
          :maxlength="1000"
          :disabled="isEnabled ? false : true"
          :class="!isEnabled ? 'isEdit' : ''"
          class="border border-gray-200 p-2 rounded h-44"
          placeholder="Write Details Here"
        ></textarea>
        <span v-if="errors.complaintDetails" class="text-red-500 text-xs">{{
          errors.complaintDetails
        }}</span>
        <small v-if="isEnabled">Your Details between 0-1000 Characters</small>
      </div>
      <div class="flex mt-5 gap-4">
        <div class="w-full flex flex-col gap-2">
          <label class="text-sm">
            Complainant
            <span v-if="isEnabled" class="text-red-500">*</span>
          </label>
          <input
            v-model="complainant"
            @change="errors.complainant = null"
            type="text"
            :maxlength="50"
            :disabled="isEnabled ? false : true"
            class="border border-gray-200 p-2 rounded"
            :class="!isEnabled ? 'isEdit' : ''"
          />
          <span v-if="errors.complainant" class="text-red-500 text-xs">{{
            errors.complainant
          }}</span>
        </div>
        <div class="w-full flex flex-col gap-2">
          <label class="text-sm">
            Complainant Relationship
            <span v-if="isEnabled" class="text-red-500">*</span>
          </label>
          <v-select
            v-if="isEnabled"
            v-model="complainantRelationship"
            label="practice_name"
            @update:modelValue="errors.complainantRelationship = ''"
            placeholder="Select Complainant Relationship"
            :options="complainantRelationshipOptions"
          ></v-select>
          <div v-else class="border border-gray-200 py-2 px-2 rounded isEdit">
            {{ complainantRelationship }}
          </div>
          <span
            v-if="errors.complainantRelationship"
            class="text-red-500 text-xs"
            >{{ errors.complainantRelationship }}</span
          >
        </div>
      </div>
      <div class="w-full flex flex-col gap-2 mt-5" v-if="isEnabled">
        <label class="text-sm">
          Complainant Email
          <span v-if="isEnabled" class="text-red-500">*</span>
        </label>
        <input
          v-model="complainantEmail"
          @change="errors.complainantEmail = null"
          type="email"
          placeholder="user@gmail.com"
          :disabled="isEnabled ? false : true"
          class="border border-gray-200 p-2 rounded"
          :class="!isEnabled ? 'isEdit' : ''"
          @blur="validateEmail(complainantEmail)"
        />
        <span
          v-if="!isValidEmail && !errors.complainantEmail"
          class="text-red-500 text-xs"
          >Please Enter Valid Email</span
        >
        <span v-if="errors.complainantEmail" class="text-red-500 text-xs">{{
          errors.complainantEmail
        }}</span>
      </div>
      <div class="flex mt-5 gap-4">
        <div class="w-full flex flex-col gap-2">
          <label class="text-sm">
            Patient Name
            <span v-if="isEnabled" class="text-red-500">*</span>
          </label>
          <input
            v-model="patientName"
            @change="errors.patientName = null"
            type="text"
            :maxlength="50"
            :disabled="isEnabled ? false : true"
            class="border border-gray-200 p-2 rounded"
            :class="!isEnabled ? 'isEdit' : ''"
          />
          <span v-if="errors.patientName" class="text-red-500 text-xs">{{
            errors.patientName
          }}</span>
        </div>
        <div class="w-full flex flex-col gap-2">
          <label class="text-sm">
            Patient Age Group
            <span v-if="isEnabled" class="text-red-500">*</span>
          </label>
          <v-select
            v-if="isEnabled"
            v-model="patientAgeGroup"
            label="practice_name"
            placeholder="Select Age Group"
            @update:modelValue="errors.patientAgeGroup = ''"
            :options="ageGroupOptions"
          ></v-select>
          <div v-else class="border border-gray-200 py-2 px-2 rounded isEdit">
            {{ patientAgeGroup }}
          </div>
          <span v-if="errors.patientAgeGroup" class="text-red-500 text-xs">{{
            errors.patientAgeGroup
          }}</span>
        </div>
      </div>
      <div class="flex flex-col mt-5 gap-2">
        <label class="text-sm">
          Receiving Date
          <span v-if="isEnabled" class="text-red-500">*</span>
        </label>
        <DatePicker
          :selectedDate="receivingDate"
          @selected-date="atReceivingDate"
          @cleared-date="atReceivingDate"
          :isDisabled="isEnabled ? false : true"
          :needDisableColor="isEnabled ? false : true"
          :needMaxDate="true"
          :maxDate="date"
          placeholder="Date"
          class="w-full border border-gray-200 rounded"
        />
      </div>
      <span v-if="errors.receivingDate" class="text-red-500 text-xs">{{
        errors.receivingDate
      }}</span>
      <div
        class="flex flex-col mt-5 gap-2"
        v-if="complaint?.status >= 1 && !isEnabled"
      >
        <label class="text-sm">Acknowledgement Date</label>
        <DatePicker
          :selectedDate="
            complaint?.complaint_acknowledgement?.acknowledgement_date
          "
          :isDisabled="isEnabled ? false : true"
          :needDisableColor="isEnabled ? false : true"
          placeholder="Date"
          class="w-full border border-gray-200 rounded"
        />
      </div>
      <div>
        <div
          class="flex items-center justify-center border-teal border-dashed border-2 h-32 rounded-md my-8 cursor-pointer"
          v-bind="getRootProps()"
          v-if="!acceptedFiles"
        >
          <input v-bind="getInputProps()" />
          <div class="flex items-center">
            Drag or upload files here or
            <div class="cursor-pointer text-teal">&nbsp;Browse here</div>
            <div class="material-icons text-teal pl-1">attachment_outlined</div>
          </div>
        </div>
        <div v-if="acceptedFiles">
          <div v-if="!isManager">
            <div class="text-sm mt-4 mb-2">
              Files
              <span v-if="isEnabled" class="text-red-500">*</span>
            </div>
            <div
              class="p-4 flex w-full gap-4 rounded-md bg-teal bg-opacity-10 justify-between"
            >
              <div class="flex gap-2 flex-wrap">
                <div class="flex items-center justify-start" v-if="isEnabled">
                  <span
                    v-if="loadingButton"
                    class="border-2 border-black rounded-full w-4 h-4 animate-spin"
                  ></span>
                </div>
                <div v-for="(item, key) in acceptedFiles" :key="key">
                  <div class="bg-teal bg-opacity-25 px-2 rounded-md">
                    <div
                      class="text-md line-clamp-1 capitalize flex items-center truncate"
                      :class="!isEnabled ? ' cursor-pointer' : ''"
                      @click="
                        !isEnabled ? downloadFile(item?.file, item.name) : ''
                      "
                    >
                      {{
                        item
                          ? item.name?.length > 25
                            ? item.name.slice(0, 15) +
                              "..." +
                              item.name.slice(-8)
                            : item.name
                          : ""
                      }}
                      <span
                        class="material-icons w-5 text-red px-1 cursor-pointer text-lg"
                        v-if="isEnabled && !loadingButton"
                        @click="removeSingleFile(key, item)"
                        >close</span
                      >

                      <span
                        class="material-icons w-5 text-teal px-1 text-lg cursor-pointer"
                        v-if="!isEnabled"
                        >save_alt_icon</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mt-4">
            <div class="text-sm mb-2">Files</div>
            <div class="grid grid-cols-2 gap-4">
              <div v-for="(item, key) in acceptedFiles" :key="key">
                <div
                  class="w-full isEdit py-3 px-5 rounded-md flex justify-between border border-gray-200"
                >
                  <div class="flex gap-2">
                    <img src="../../../assets/icons/compliance_File.svg" />
                    <div
                      class="text-md line-clamp-2 capitalize flex items-center"
                    >
                      {{
                        item
                          ? item.name?.length > 35
                            ? item.name?.slice(0, 25) +
                              "..." +
                              item.name?.slice(-8)
                            : item.name
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    class="w-1/4 flex items-center justify-end cursor-pointer"
                    @click="downloadFile(item?.file, item?.name)"
                  >
                    <button class="text-teal font-medium">Download</button>
                    <span class="material-icons w-5 text-teal px-1"
                      >save_alt_icon</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isEnabled && !isManager">
            <div>
              <div
                v-bind="getRootProps()"
                class="flex items-center justify-end cursor-pointer mt-2"
              >
                <input v-bind="getInputProps()" />
                <div
                  class="material-icons text-teal w-5 transform -rotate-90 text-2xl mr-1"
                >
                  attachment_outlined
                </div>
                <button
                  class="cursor-pointer text-sm text-gray-600"
                  :disabled="loadingButton"
                >
                  Add more Files
                </button>
              </div>
            </div>
          </div>
        </div>
        <small v-if="errors.acceptedFiles" class="text-red">{{
          errors.acceptedFiles
        }}</small>
        <small v-if="!isFileUploaded.flag" class="text-red">{{
          isFileUploaded.msg
        }}</small>
      </div>
      <div class="flex items-center mt-5 cursor-pointer" v-if="isEnabled">
        <div
          class="rounded-full w-5 h-5 border border-gray-400 flex items-center justify-center"
          @click="atVisibleToManagerClick"
          :class="isVisibleToManager ? ' bg-teal' : 'bg-white'"
        >
          <div class="rounded-full w-3 h-3 bg-white"></div>
        </div>

        <label class="pl-1 text-teal-600">Visible to Manager</label>
      </div>
      <div class="flex justify-between my-5" v-if="isEnabled">
        <button
          class="border-teal border py-3 px-10 rounded-md text-teal"
          @click="cancelEdit"
        >
          Cancel
        </button>
        <button
          class="py-3 px-10 bg-teal text-white rounded-md font-light"
          :class="loadingButton ? 'bg-opacity-10 cursor-not-allowed' : ''"
          @click="saveComplaint"
          :disabled="loadingButton"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, watch, defineEmits } from "vue";
import { useDropzone } from "vue3-dropzone";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import useDownloadFile from "@/composables/useDownloadFile";
const { getRootProps, getInputProps, ...rest } = useDropzone({
  onDrop,
  maxSize: 2000000,
  accept: [".pdf", ".docx"],
});

const router = useRouter();
const complainantRelationshipOptions = ref([
  "Patient",
  "Parent",
  "Guardian",
  "Carer",
  "Other",
]);
const ageGroupOptions = ref([
  "Age 0-19",
  "Age 20-59",
  "Age 60+",
  "Age Unknown",
]);
const { downloadFile } = useDownloadFile();
const acceptedFiles = ref(null);
const restVlaue = ref(null);
const isFileUploaded = ref({ flag: true, msg: "" });
const store = useStore();

const loadingButton = ref(false);
const deletedFiles = ref([]);
const receivingDate = ref(null);
const selectedSubject = ref(null);
const subjectName = ref();
const practiceName = ref();
const selectedPracticeId = ref(null);
const selectedStaffGroup = ref(null);
const staffGroupName = ref();
const complaintDetails = ref("");
const complainant = ref("");
const complainantRelationship = ref(null);
const complainantEmail = ref("");
const patientName = ref("");
const isVisibleToManager = ref(0);
const patientAgeGroup = ref(null);
const isValidEmail = ref(true);
const date = new Date();

const errors = ref({
  selectedSubject: null,
  selectedStaffGroup: null,
  selectedPracticeId: null,
  complaintDetails: null,
  complainant: null,
  complainantRelationship: null,
  complainantEmail: null,
  patientName: null,
  patientAgeGroup: null,
  acceptedFiles: null,
});

const emit = defineEmits(["at-cancel-clicked"], ["after-edit-response"]);
const props = defineProps({
  isEnabled: {
    type: Number,
  },
  staffGroup: {
    type: Array,
    required: true,
  },
  subjectArea: {
    type: Array,
    required: true,
  },
  practices: {
    type: Array,
    required: true,
  },
  complaint: {
    type: Object,
    required: true,
  },
  isManager: {
    type: Boolean,
  },
  isEditable: {
    type: Boolean,
  },
  loader: {
    type: Boolean,
  },
});

const {
  isEnabled,
  staffGroup,
  subjectArea,
  practices,
  complaint,
  isManager,
  isEditable,
  loader,
} = toRefs(props);

const atVisibleToManagerClick = () => {
  isVisibleToManager.value
    ? (isVisibleToManager.value = 0)
    : (isVisibleToManager.value = 1);
};

const validateEmail = (email) => {
  const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;

  if (emailRegex.test(email)) {
    isValidEmail.value = true;
  } else {
    isValidEmail.value = false;
  }
};

function onDrop(acceptedFilesNew, rejectReasons) {
  errors.value.acceptedFiles = "";
  if (acceptedFiles.value) {
    acceptedFiles.value = [...acceptedFiles.value, ...acceptedFilesNew];
    isFileUploaded.value.flag = true;
    isFileUploaded.value.msg = "";
    if (rejectReasons) {
      rejectReasons.map((item) => {
        if (item.errors[0].message.includes("Too many files")) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "Too many files";
        }
        if (
          item.errors[0].message.includes("File is larger than 2000000 bytes")
        ) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "File Size Must Be Smaller Than 2MB";
        }
      });
      restVlaue.value = rest;
    }
  } else {
    acceptedFiles.value = acceptedFilesNew;
    isFileUploaded.value.flag = true;
    isFileUploaded.value.msg = "";
    if (rejectReasons) {
      rejectReasons.map((item) => {
        if (item.errors[0].message.includes("Too many files")) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "Too many Files";
          acceptedFiles.value = null;
        }
        if (
          item.errors[0].message.includes("File is larger than 2000000 bytes")
        ) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "File Size Must Be Smaller Than 2MB";
          acceptedFiles.value = null;
        }
      });
    }
    restVlaue.value = rest;
  }
}

const removeSingleFile = (id, file) => {
  if (acceptedFiles.value?.length == 1) {
    if (file?.file) deletedFiles.value.push(file?.id);
    acceptedFiles.value = null;
  } else {
    if (file?.file) deletedFiles.value.push(file?.id);
    acceptedFiles.value.splice(id, 1);
  }
};

const atReceivingDate = (selectedDate) => {
  errors.value.receivingDate = "";
  if (selectedDate) {
    receivingDate.value = selectedDate;
  } else {
    receivingDate.value = null;
  }
};

const choosenPractice = (practice) => {
  errors.value.selectedPracticeId = "";
  if (practice) {
    selectedPracticeId.value = practice?.id;
    practiceName.value = practice.practice_name;
  } else selectedPracticeId.value = null;
};

const choosenSubject = (subject) => {
  errors.value.selectedSubject = "";
  if (subject) {
    selectedSubject.value = subject.id;
    subjectName.value = subject.name;
  } else selectedSubject.value = null;
};

const choosenStaffGroup = (staffGroup) => {
  errors.value.selectedStaffGroup = "";
  if (staffGroup) {
    selectedStaffGroup.value = staffGroup.id;
    staffGroupName.value = staffGroup.name;
  } else selectedStaffGroup.value = null;
};

const validateForm = () => {
  errors.value = {};
  let hasErrors = false;

  if (!selectedSubject.value) {
    errors.value.selectedSubject = "Subject is required";
    hasErrors = true;
  } else {
    errors.value.selectedSubject = "";
  }

  if (!selectedStaffGroup.value) {
    errors.value.selectedStaffGroup = "Staff group is required";
    hasErrors = true;
  } else {
    errors.value.selectedStaffGroup = "";
  }

  if (!selectedPracticeId.value) {
    errors.value.selectedPracticeId = "Practice is required";
    hasErrors = true;
  } else {
    errors.value.selectedPracticeId = "";
  }

  if (!complaintDetails.value) {
    errors.value.complaintDetails = "Complaint details are required";
    hasErrors = true;
  } else {
    errors.value.complaintDetails = "";
  }

  if (!complainant.value || complainant.value.length < 3) {
    errors.value.complainant =
      "Complainant Name is required (Minimum 3 Character)";
    hasErrors = true;
  } else {
    errors.value.complainant = "";
  }

  if (!complainantRelationship.value) {
    errors.value.complainantRelationship =
      "Complainant relationship is required";
    hasErrors = true;
  } else {
    errors.value.complainantRelationship = "";
  }

  if (!complainantEmail.value) {
    errors.value.complainantEmail = "Complainant email is required";
    hasErrors = true;
  } else {
    errors.value.complainantEmail = "";
  }

  if (!patientName.value) {
    errors.value.patientName = "Patient name is required";
    hasErrors = true;
  } else {
    errors.value.patientName = "";
  }

  if (!patientAgeGroup.value) {
    errors.value.patientAgeGroup = "Patient age group is required";
    hasErrors = true;
  } else {
    errors.value.patientAgeGroup = "";
  }

  if (!acceptedFiles.value) {
    errors.value.acceptedFiles = "Files are required";
    hasErrors = true;
  } else {
    errors.value.acceptedFiles = "";
  }

  if (!receivingDate.value) {
    errors.value.receivingDate = "Receiving date is required";
    hasErrors = true;
  } else {
    errors.value.receivingDate = "";
  }

  if (hasErrors) {
    return true;
  }
};

const saveComplaint = () => {
  if (validateForm()) {
    return;
  }
  const formData = new FormData();
  formData.append("subject", selectedSubject.value);
  formData.append("staff_group", selectedStaffGroup.value);
  formData.append("practice", selectedPracticeId.value);
  formData.append("details", complaintDetails.value);
  formData.append("complainant_name", complainant.value);
  formData.append("complainant_email", complainantEmail.value);
  formData.append("complainant_relation", complainantRelationship.value);
  formData.append("age_group", patientAgeGroup.value);
  formData.append("patient_name", patientName.value);
  formData.append("receiving_date", receivingDate.value);
  formData.append("manager_visibility", isVisibleToManager.value);

  if (acceptedFiles.value) {
    acceptedFiles.value.forEach((file, index) => {
      if (!(file?.file && isEditable.value)) {
        formData.append(`files[${index}]`, file);
      }
    });
  }

  loadingButton.value = true;
  if (!isEditable.value) {
    store
      .dispatch("hqComplaints/createComplaint", formData)
      .then(() => {
        loadingButton.value = false;
        Swal.fire({
          title: `Your Complaint Is Successfully lodged`,
          iconHtml: `<img src="${icon}" />`,
          customClass: {
            title: "custom-title",
            popup: "border-radius",
            content: "custom-text",
            icon: "icon-border",
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
        });
        router.push({
          name: "hq-monitoringOfPracticeFunctionComplaintsDashboard",
          params: {
            isManager: 0,
          },
        });
      })
      .catch(() => {
        loadingButton.value = false;
      });
  } else {
    if (deletedFiles.value) {
      deletedFiles.value.forEach((file, index) => {
        formData.append(`deleted_files[${index}]`, file);
      });
    }
    formData.append("complaint", complaint.value?.id);
    store
      .dispatch("hqComplaints/editComplaint", formData)
      .then(() => {
        emit("after-edit-response", false);
        loadingButton.value = false;
        Swal.fire({
          title: `Your Complaint Is Updated Successfully`,
          iconHtml: `<img src="${icon}" />`,
          customClass: {
            title: "custom-title",
            popup: "border-radius",
            content: "custom-text",
            icon: "icon-border",
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
        });
      })
      .catch(() => {
        loadingButton.value = false;
      });
  }
};

const cancelEdit = () => {
  errors.value = {
    selectedSubject: null,
    selectedStaffGroup: null,
    selectedPracticeId: null,
    complaintDetails: null,
    complainant: null,
    complainantRelationship: null,
    complainantEmail: null,
    patientName: null,
    patientAgeGroup: null,
    acceptedFiles: null,
  };
  if (isEditable.value) {
    subjectName.value = complaint.value?.complaint_subject?.name;
    selectedSubject.value = complaint.value?.complaint_subject?.id;
    staffGroupName.value = complaint.value?.complaint_staff_group?.name;
    selectedStaffGroup.value = complaint.value?.complaint_staff_group?.id;
    practiceName.value = complaint.value?.complaint_practice?.practice_name;
    selectedPracticeId.value = complaint.value?.complaint_practice?.id;
    complaintDetails.value = complaint.value?.details;
    complainant.value = complaint.value?.complainant_name;
    complainantRelationship.value = complaint.value?.complainant_relation;
    complainantEmail.value = complaint.value?.complainant_email;
    patientName.value = complaint.value?.patient_name;
    patientAgeGroup.value = complaint.value?.age_group;
    receivingDate.value = complaint.value?.receiving_date;
    isVisibleToManager.value = complaint.value?.manager_visibility;
    acceptedFiles.value = complaint.value?.complaint_files?.map((file) => ({
      id: file?.id,
      complaint: file?.complaint,
      file: file?.file,
      name: file?.file_name,
    }));
    emit("at-cancel-clicked", false);
  }
};

watch(
  () => complaint.value,
  (newVal) => {
    if (!isEnabled.value) {
      subjectName.value = newVal?.complaint_subject?.name;
      selectedSubject.value = newVal?.complaint_subject?.id;
      staffGroupName.value = newVal?.complaint_staff_group?.name;
      selectedStaffGroup.value = newVal?.complaint_staff_group?.id;
      practiceName.value = newVal?.complaint_practice?.practice_name;
      selectedPracticeId.value = newVal?.complaint_practice?.id;
      complaintDetails.value = newVal?.details;
      complainant.value = newVal?.complainant_name;
      complainantRelationship.value = newVal?.complainant_relation;
      complainantEmail.value = newVal?.complainant_email;
      patientName.value = newVal?.patient_name;
      patientAgeGroup.value = newVal?.age_group;
      receivingDate.value = newVal.receiving_date;
      isVisibleToManager.value = newVal?.manager_visibility;
      acceptedFiles.value = newVal.complaint_files?.map((file) => ({
        id: file?.id,
        complaint: file?.complaint,
        file: file?.file,
        name: file?.file_name,
      }));
    }
  },
  { immediate: true }
);
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 7px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep .vs__search {
  --vs-search-input-placeholder-color: rgb(148, 148, 148);
}
.swal2-cancel.swal2-styled {
  border-color: red !important;
}

::-webkit-calendar-picker-indicator {
  background-image: url("../../../assets/icons/calendar_month.svg");
}

input[type="date"]::-webkit-datetime-edit {
  display: none;
}

input[type="radio"]#is-visible {
  accent-color: teal;
}

.isEdit {
  background: rgba(231, 243, 242, 0.65);
}
</style>
